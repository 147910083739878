


































import Vue from "vue";
import Component from "vue-class-component";
@Component({})
export default class NeedAStoreMobile extends Vue {
    public name = "NeedAStore";
    private showButton = false;
    private hideCompletely = false;

    private needClicked() {
        this.$emit("clicked");
    }

    private hideButton() {
        this.hideCompletely = true;
    }
}
