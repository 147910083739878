import { WebHelper } from "chipply-common";
import IEventBrandingDto from "../IEventBrandingDto";
import IEventBrandingResults from "../IEventBrandingResults";
import IEventCategoriesDto from "../i-event-categories-dto";
import IEventCategoryDto from "../i-event-category-dto";

export default class StoreViewModel {
    public branding: IEventBrandingDto = {} as any;
    public cartQuantity = 0;
    public isPreview = false;
    public youtubeEmbed = "";
    public fundraisingText = " $0 raised";
    public fundraisingPercentage = 0;

    public categories: IEventCategoriesDto = {
        isSingleCategoryExpansion: false,
        products: { products: [], lastPage: false, currentPage: 0 },
        containers: [],
        hexCode1: "",
        foregroundColor: "",
        storePasswordNeedsValidation: false,
    };

    public openCategories: IEventCategoryDto[] = [];

    public selectedCategory: IEventCategoryDto[] = [];

    public async get(eventId: number): Promise<void> {
        let baseUrl = "/api/Event/Branding?eventId=";
        baseUrl += encodeURIComponent(eventId);
        const results = (await WebHelper.getJsonData(baseUrl)) as IEventBrandingResults;
        if (results && results.dto) {
            this.branding = results.dto;
            this.ready();
        }
    }

    protected ready() {
        this.initializeYouTubeEmbed();
        this.setFundraisingText();
        this.setFundraisingPercentage();
    }

    protected initializeYouTubeEmbed() {
        if (this.branding.slideVideo) {
            const videoId = this.getYouTubeId(this.branding.slideVideo);
            this.youtubeEmbed = `https://www.youtube-nocookie.com/embed/${videoId}?&modestbranding=1&autohide=1&showinfo=0&controls=1&autoplay=1&mute=1&loop=1&playlist=${videoId}`;
        }
    }

    protected setFundraisingPercentage() {
        if (this.branding.fundraisingAmount > 0 && this.branding.fundraisingGoal > 0) {
            this.fundraisingPercentage = (this.branding.fundraisingAmount / this.branding.fundraisingGoal) * 100;
        }
    }

    protected setFundraisingText() {
        if (this.branding.shouldIncludeFundraising) {
            const fundraisingAmount = this.toCurrency(this.branding.fundraisingAmount);
            if (this.branding.fundraisingGoal > 0) {
                const fundraisingGoal = this.toCurrency(this.branding.fundraisingGoal);
                this.fundraisingText = `${fundraisingAmount} raised of ${fundraisingGoal}`;
            } else {
                this.fundraisingText = `${fundraisingAmount} raised`;
            }
        }
    }

    protected toCurrency(value: number): string {
        const formatter = new Intl.NumberFormat("en-US", {
            currency: "USD",
            style: "currency",
        });
        return formatter.format(value);
    }

    protected getYouTubeId(url: string): string {
        let videoId = "";
        if (url.indexOf("v=") > 0) {
            // Handle urls in format https://www.youtube.com/watch?v=6qg8yxL0ASs
            videoId = url.split("v=")[1];
            const ampersandPosition = videoId.indexOf("&");
            if (ampersandPosition !== -1) {
                videoId = videoId.substring(0, ampersandPosition);
            }
        } else if (url.indexOf("/") > 0) {
            // Handle urls in format https://youtu.be/6qg8yxL0ASs
            const splitUrl = url.split("/");
            if (splitUrl.length > 0) {
                return splitUrl[splitUrl.length - 1];
            }
        }
        return videoId;
    }
}
