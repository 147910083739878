





















import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import IProductDto from "@/chipply/i-product-dto";
import { WebHelper } from "../../../../common";
import IDesignImageResults from "@/chipply/IDesignImageResults";
@Component({})
export default class CCarousel extends Vue {
    @Prop({ default: () => [], type: Array })
    public designs!: any[];

    @Prop({ type: Object })
    public product!: IProductDto;

    @Prop({ type: Boolean })
    public bigCard = false;

    @Prop({ type: String })
    public carouselUrl!: string;

    public loading = false;

    private carouselClassObject = {
        "w3-animate-right": false,
        "w3-animate-left": false,
    };

    private async staggerTransition(forward: boolean) {
        this.carouselClassObject["w3-animate-right"] = false;
        this.carouselClassObject["w3-animate-left"] = false;
        await this.$nextTick(() => {});
        if (forward) {
            this.carouselClassObject["w3-animate-right"] = true;
        } else {
            this.carouselClassObject["w3-animate-left"] = true;
        }
    }

    private styleObject = {
        visibility: "visible",
        marginTop: "20px !important",
        maxWidth: "300px",
    };

    @Watch("bigCard")
    protected async switchSizes() {
        if (this.bigCard) {
            this.styleObject.maxWidth = "449px";
        } else {
            this.styleObject.maxWidth = "300px";
        }
    }

    private async nextImage() {
        this.loading = true;
        this.$emit("nextImage");
        await this.staggerTransition(true);
        this.loading = false;
    }

    private async prevImage() {
        this.loading = true;
        this.$emit("prevImage");
        await this.staggerTransition(false);
        this.loading = false;
    }
}
