












import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Watch } from "vue-property-decorator";

@Component({
    props: {
        bigCards: Boolean,
    },
    components: {},
})
export default class SmallCardIcon extends Vue {
    public bigCards!: boolean;

    private fillColor(): string {
        let value = "";
        if (this.bigCards) {
            value = "#E3E2E5";
        } else {
            value = "#909093";
        }
        return value;
    }
}
