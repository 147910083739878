



































































































































































































































































































































































































































































































import CategoryPasswordAuthenticator from "@/components/CategoryPasswordAuthenticator.vue";
import Component from "vue-class-component";
import Vue from "vue";
import EcomLayout from "./EcomLayout.vue";
import { Watch, Prop } from "vue-property-decorator";
import StoreViewModel from "@/chipply/view-model/StoreViewModel";
import CountdownTimer from "@/components/CountdownTimer.vue";
import ProductShell from "@/pages/product-shell";
import IEventCategoryDto from "@/chipply/i-event-category-dto";
import CartViewModel from "@/chipply/view-model/CartViewModel";
import ProductStorefront from "./ProductStorefront.vue";
import ProductDisplayCard from "./ProductDisplayCard.vue";
import IEventProperties from "@/chipply/i-event-properties";
import StorePackage from "./StorePackage.vue";
import SmallCardIcon from "@/components/SmallCardIcon.vue";
import BigCardIcon from "@/components/BigCardIcon.vue";
import NeedAStoreButton from "@/components/NeedAStoreButton.vue";
import NeedAStoreMobile from "@/components/NeedAStoreMobile.vue";
import NeedAStoreDialogue from "@/components/NeedAStoreDialogue.vue";

@Component({
    components: {
        NeedAStoreDialogue,
        NeedAStoreMobile,
        NeedAStoreButton,
        BigCardIcon,
        SmallCardIcon,
        EcomLayout,
        ProductShell,
        ProductStorefront,
        CountdownTimer,
        ProductDisplayCard,
        StorePackage,
        CategoryPasswordAuthenticator,
    },
})
export default class NewStore extends Vue {
    public name = "NewStore";
    public vm: StoreViewModel = new StoreViewModel();
    public cartVm: CartViewModel = new CartViewModel();
    public eventId = 0;
    public isPreview = false;
    public loaded!: boolean;
    public selectedProductId!: number;
    public selectedCategoryId!: number;
    public action: string | null = null;
    public tab = 0;
    public eventProperties: any = {};
    public initialized = false;
    public initializing = false;
    public showNeedDialogue = false;
    public showNeedButton = true;

    // public selectedCategory: IEventCategoryDto[] = [];

    // @ts-ignore
    public $refs!: {
        productShell: ProductStorefront;
        ecomLayout: EcomLayout;
        video: HTMLIFrameElement;
    };

    protected readonly eventIdParamName = "eid";
    protected readonly acidParamName = "acid";
    protected readonly apidParamName = "apid";
    protected readonly actionParamName = "action";
    protected readonly ViewAllAction = "viewall";

    protected done = false;
    protected player: any;

    public async created() {
        this.assignFromUrl();
        if (!this.eventId) return;
        await this.initialize();
    }

    @Watch("eventId")
    public async onEventIdChanged() {
        await this.initialize();
    }

    private assignFromUrl() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has(this.acidParamName)) {
            this.selectedCategoryId = Number(urlParams.get(this.acidParamName));
        }
        if (urlParams.has(this.apidParamName)) {
            this.selectedProductId = Number(urlParams.get(this.apidParamName));
        }
        if (urlParams.has(this.eventIdParamName)) {
            this.eventId = Number(urlParams.get(this.eventIdParamName));
        }

        if (urlParams.has(this.actionParamName)) {
            this.action = urlParams.get(this.actionParamName);
        }
    }

    public get hasCategories() {
        return this.cartVm.categories && this.cartVm.categories.containers.length > 0;
    }

    public getViewAllTabIndex() {
        return this.cartVm.categories.isSingleCategoryExpansion ? 1 : 0;
    }
    private async initialize() {
        if (this.initialized || this.initializing) return;
        this.initializing = true;

        await this.vm.get(this.eventId);
        await this.cartVm.get(this.eventId);
        await this.cartVm.getCartItems(this.eventId, false);
        await this.cartVm.getCategories(this.eventId);
        this.cartVm.initialized = true;

        if (this.hasCategories) {
            const focused = this.tryFocusSelectedCategory();
            if (!focused) {
                this.setEventProperties(0, 0);
                if (this.action == this.ViewAllAction) {
                    this.tab = this.getViewAllTabIndex();
                }
            }
        } else if (!this.eventProperties.eventID) {
            // TODO: This code is here for paging in local development, we can clean this up later
            this.setEventProperties(0, this.selectedProductId);
        }

        window.addEventListener("load", async () => {
            this.constrainSlideLogoAsNeeded();
        });
        this.initializing = false;
        this.initialized = true;
    }
    public async updated() {
        await this.$nextTick();
        this.constrainSlideLogoAsNeeded();
    }

    public async mounted() {
        await this.$nextTick();
        this.constrainSlideLogoAsNeeded();
    }

    public async validated(cat: IEventCategoryDto, ev: boolean) {
        if (ev) {
            if (cat.containers && cat.containers.length > 0) {
                const firstContainer = cat.containers[0];
                if (firstContainer.isPackage) {
                } else {
                    cat.activeSubCategory = firstContainer as IEventCategoryDto;
                }
            }
            cat.isAuthorized = true;
        }
    }

    public getTab(i: number) {
        let offset = this.cartVm.categories.isSingleCategoryExpansion ? 2 : 1;
        return offset + i;
    }

    private getCategoryIndexFromTab(tab: number) {
        let offset = this.cartVm.categories.isSingleCategoryExpansion ? 1 : 0;
        return tab - offset;
    }

    private tryFocusSelectedCategory() {
        if (this.cartVm.categories.containers.length === 0) return false;

        if (!this.selectedCategoryId) {
            return false;
        }

        for (let i = 0; i < this.cartVm.categories.containers.length; i++) {
            const currentCat = this.cartVm.categories.containers[i];
            if (currentCat.eventCategoryID == this.selectedCategoryId) {
                this.tab = this.getTab(i);
                this.setEventProperties(this.selectedCategoryId, this.selectedProductId);
                return true;
            }

            if (!currentCat.isPackage) {
                const category = currentCat as IEventCategoryDto;
                for (let subCat of category.containers || []) {
                    if (subCat.eventCategoryID == this.selectedCategoryId) {
                        category.activeSubCategory = subCat;
                        this.tab = this.getTab(i);
                        this.setEventProperties(this.selectedCategoryId, this.selectedProductId);
                        return true;
                    }
                }
            }
        }
        return false;
    }
    private changeSubCategory(cat: IEventCategoryDto, subCat: IEventCategoryDto) {
        const tab = this.cartVm.categories.containers.indexOf(cat);
        this.tab = this.getTab(tab);
        cat.activeSubCategory = subCat;
        cat.containers.forEach((sub) => {
            if (sub === subCat) {
                this.setEventProperties(sub.eventCategoryID);
            }
        });
    }

    private setEventProperties(selectedCategoryID: number, selectedProductId?: number) {
        const eventID = this.eventId;
        const dealerId = this.cartVm.branding.dealerId;
        this.eventProperties = {
            eventID: eventID,
            dealerID: dealerId,
            selectedCategoryID: selectedCategoryID,
            selectedProductID: selectedProductId,
        };
    }

    private tryDefaultToFirstSubcategory() {
        let index = this.getCategoryIndexFromTab(this.tab);
        const selectedParentCategory = this.cartVm.categories.containers[index] as IEventCategoryDto;
        if (selectedParentCategory.hasPassword && !selectedParentCategory.isAuthorized) {
            return false;
        }
        if (!selectedParentCategory.containers || selectedParentCategory.containers.length === 0) {
            return false;
        }

        selectedParentCategory.activeSubCategory = selectedParentCategory.containers[0] as IEventCategoryDto;
        this.setEventProperties(selectedParentCategory.activeSubCategory.eventCategoryID);

        return true;
    }

    private async updateClickedCategory() {
        let index = this.getCategoryIndexFromTab(this.tab);
        const clickedParentCategory = this.cartVm.categories.containers[index] as IEventCategoryDto;
        // Sometimes clickedParentCategory is unset, probably due to a timing issue?
        if (clickedParentCategory) {
            await this.setEventProperties(clickedParentCategory.eventCategoryID);
        }
    }

    // TODO: Temporary fix to deal with issue slide logo image growing taller than the slide image on mobile.
    // Determine if there is a cleaner way to handle this.
    protected constrainSlideLogoAsNeeded() {
        setTimeout(() => {
            const slideImageLogo = document.getElementById("slideImageLogo");
            const slideImage = document.getElementById("slideImage");
            if (slideImageLogo && slideImage) {
                const clientHeight = (slideImage as HTMLElement).getBoundingClientRect().height;
                const slideLogoClientHeight = (slideImageLogo as HTMLElement).getBoundingClientRect().height;
                if (clientHeight > 0 && slideLogoClientHeight > clientHeight) {
                    (slideImageLogo as HTMLElement).style.maxHeight = clientHeight.toString() + "px";
                }
            }
        }, 500);
    }

    protected selectCategory(eventCategoryId: number) {
        this.$refs.productShell.selectCategory(eventCategoryId);
    }

    protected async treeSelectCategory(items: IEventCategoryDto[]) {
        if (!items || items.length === 0) {
            return;
        }
        if (this.$refs.ecomLayout.drawer) {
            this.$refs.ecomLayout.drawer = false;
        }
        await this.categoryClick(items[0]);
        this.$refs.productShell.selectCategory(items[0].eventCategoryID);
    }

    protected async categoryClick(item: IEventCategoryDto) {
        let index = this.vm.categories.containers.findIndex((c) => c.id === item.id);
        if (index < 0) {
            for (let i = 0; i < this.vm.categories.containers.length; i++) {
                const cat = this.vm.categories.containers[i] as IEventCategoryDto;
                if (cat.containers) {
                    if (cat.containers.findIndex((c) => c.id === item.id) >= 0) {
                        index = i;
                        break;
                    }
                }
            }
        }

        if (this.vm.categories.isSingleCategoryExpansion) {
            this.$refs.productShell.panelToggles = index;
        } else {
            if (this.$refs.productShell.panelTogglesArray.indexOf(index) < 0) {
                this.$refs.productShell.panelTogglesArray.push(index);
            }
        }

        await this.$nextTick(() => {
            const categories: IEventCategoryDto[] = [];

            // For now ignore packages for expansion
            for (let currentItem of this.vm.categories.containers) {
                currentItem = currentItem as IEventCategoryDto;
                categories.push(currentItem);

                const subcategories: IEventCategoryDto[] = [];
                const parentIndex = categories.indexOf(currentItem);

                for (let subcategory of currentItem.containers) {
                    subcategory = subcategory as IEventCategoryDto;
                    subcategories.push(subcategory);
                    const subcategoryIndex = subcategories.indexOf(item);
                    if (subcategoryIndex < 0) {
                        continue;
                    }
                    if (this.vm.categories.isSingleCategoryExpansion) {
                        this.$refs.productShell.subcategoryToggles[parentIndex].toggle = subcategoryIndex;
                    } else if (subcategoryIndex >= 0) {
                        if (
                            this.$refs.productShell.subcategoryTogglesArray[parentIndex].indexOf(subcategoryIndex) < 0
                        ) {
                            this.$refs.productShell.subcategoryTogglesArray[parentIndex].push(subcategoryIndex);
                        }
                    }
                }
            }
        });
    }

    protected breakCatName(name: string) {
        var copy = name;
        var indices = [0];
        copy = copy.replace(/\s+/g, "i");
        while (/\W/.test(copy)) {
            indices.push(copy.search(/\W/));
            copy = copy.replace(/\W/, "i");
        }
        var modifier = 0;
        indices.forEach((character, index) => {
            if (index > 1) {
                modifier += 5;
                character = character + modifier;
            }
            var startingIndex = indices[index - 1] + modifier;
            var sub = name.substring(startingIndex, character + 1);
            if (index != 0) {
                name = name.replace(sub, sub + "<wbr>");
            }
        });

        return name;
    }

    protected treeOpen(items: IEventCategoryDto[]) {
        if (!items || items.length === 0) {
            return;
        }
        for (const item of items) {
            // If we select a category and it is not open in the product shell, let's open it.
            // That will allow us to scroll to the subcategories which are now visible in the category selector
            const index = this.vm.categories.containers.indexOf(item);
            if (this.vm.categories.isSingleCategoryExpansion) {
                this.$refs.productShell.panelToggles = index;
            } else if (index >= 0) {
                if (this.$refs.productShell.panelTogglesArray.indexOf(index) < 0) {
                    this.$refs.productShell.panelTogglesArray.push(index);
                }
            }
        }
    }

    private calculateTabsHeight() {
        if (this.$vuetify.breakpoint.smAndUp) {
            return "84px";
        } else {
            return "65px";
        }
    }

    public async eventValidated(validated: boolean) {
        if (!validated) {
            return;
        }

        await this.cartVm.getCategories(this.eventId);
    }
}
