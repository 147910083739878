



import Component from "vue-class-component";
import Vue from "vue";
import { Watch } from "vue-property-decorator";
import IEventProperties from "@/chipply/i-event-properties";
import NewStore from "../NewStore.vue";
@Component({
    components: { NewStore },
})
export default class StorePage extends Vue {
    public name = "StorePage";
    public eventId: number | undefined;
    public eventProperties!: IEventProperties;
    public $refs!: {
        newStore: NewStore;
    };

    public async created() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("eventid")) {
            this.eventId = Number(urlParams.get("eventid"));
            // this.$refs.store.eventId = this.eventId;
            // this.$refs.store.isPreview = false;
        }
        if (urlParams.has("eid")) {
            this.eventId = Number(urlParams.get("eid"));
        }
    }
    public data() {
        return {
            eventId: undefined,
            eventProperties: undefined,
        };
    }

    @Watch("eventProperties")
    public async onEventPropertiesChanged() {
        if (!this.eventProperties) {
            return;
        }

        this.eventId = this.eventProperties.eventID;
        //this.$refs.store.eventId = this.eventId;
        this.$refs.newStore.eventProperties = this.eventProperties;
        this.$refs.newStore.eventId = this.eventId;
        if (this.eventProperties.isPreview === true) {
            //this.$refs.store.isPreview = true;
            this.$refs.newStore.isPreview = true;
        } else {
            //this.$refs.store.isPreview = false;
            this.$refs.newStore.isPreview = false;
        }
    }
}
