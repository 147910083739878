
















import Vue from "vue";
import Component from "vue-class-component";
@Component({})
export default class NeedAStoreButton extends Vue {
    public name = "NeedAStore";

    private closeButton() {
        this.$emit("close");
    }

    private needClicked() {
        this.$emit("clicked");
    }
}
