


















































import { WebHelper } from "chipply-common";
import Component from "vue-class-component";
import Vue from "vue";
import { Watch } from "vue-property-decorator";
import IProductsDto from "@/chipply/i-products-dto";
import IProductDto from "@/chipply/i-product-dto";
import IEventProperties from "@/chipply/i-event-properties";
import IEventCategoryDto from "@/chipply/i-event-category-dto";
import CategoryPasswordAuthenticator from "@/components/CategoryPasswordAuthenticator.vue";
import ProductShell from "./ProductShell.vue";
import DesignFlag from "./DesignFlag.vue";
import IDesignImageResults from "@/chipply/IDesignImageResults";
import IndividualDisplayCard from "./IndividualDisplayCard.vue";
import BigCardIcon from "@/components/BigCardIcon.vue";
import SmallCardIcon from "@/components/SmallCardIcon.vue";
@Component({
    components: { SmallCardIcon, BigCardIcon, CategoryPasswordAuthenticator, DesignFlag, IndividualDisplayCard },
    props: {
        initialProducts: Object,
        load: Boolean,
        eventProperties: Object,
        categoryDto: Object,
    },
})
export default class ProductDisplayCard extends Vue {
    public name = "ProductDisplayCard";
    public loading = false;
    public load!: boolean;
    public eventProperties!: IEventProperties;
    public categoryDto!: IEventCategoryDto;
    public initialProducts!: IProductsDto;
    public hasLoaded = false;
    public categoryID!: number;
    public productShell!: ProductShell;
    public colorDict!: any;
    public bigCard = false;

    public productsModel: IProductsDto | null = this.initialProducts || null;

    // @ts-ignore
    public $refs: {
        products: Vue[];
        outerMost: HTMLElement;
    };

    public data() {
        return {
            hasPassword: false,
            isValidated: false,
            categoryID: null,
        };
    }

    @Watch("categoryDto")
    public async onCategoryDtoChanged() {
        if (this.categoryDto) {
            this.productsModel = this.categoryDto.products;
        } else {
            this.productsModel = null;
        }
    }

    @Watch("load")
    public async onLoadChanged() {
        await this.tryLoadingProducts();
    }

    @Watch("eventProperties")
    public async onPropertiesChanged() {
        await this.tryLoadingProducts();
    }

    public navigateToProduct(product: IProductDto, categoryDto: IEventCategoryDto) {
        // window.location.href =
        //     "product-detail.aspx?pid=" +
        //     product.eventProductID +
        //     (categoryDto ? "&cid=" + categoryDto.eventCategoryID : "");
        window.location.href =
            "product-detail-shell.aspx?pid=" +
            product.eventProductID +
            (categoryDto ? "&cid=" + categoryDto.eventCategoryID : "");
        let currentUrl = window.location.href;
        let historyUrl = new URL(currentUrl);

        if (!historyUrl.searchParams.has("apid")) {
            historyUrl.searchParams.set("apid", product.eventProductID.toString());
        } else {
            historyUrl.searchParams.delete("apid");
            historyUrl.searchParams.set("apid", product.eventProductID.toString());
        }

        if (!historyUrl.searchParams.has("acid") && categoryDto) {
            historyUrl.searchParams.set("acid", categoryDto.eventCategoryID.toString());
        } else {
            historyUrl.searchParams.delete("acid");
            if (categoryDto) {
                historyUrl.searchParams.set("acid", categoryDto.eventCategoryID.toString());
            }
        }

        history.replaceState(null, "", historyUrl.href);
    }

    public getProductVendorSku(product: IProductDto) {
        if (product.hideVendor && product.hideStyle) {
            return "";
        }
        if (product.hideVendor) {
            return product.sku;
        }
        if (product.hideStyle) {
            return product.vendorName;
        }

        return product.vendorName + " - " + product.sku;
    }

    public getProductImageAltText(product: IProductDto) {
        const id = product.eventProductID;

        return `Product Image: ${id}`;
    }

    public changeDisplayColor(product: IProductDto, hex: any) {
        //let imageUrl = product.productUrl.substring(0, product.productUrl.lastIndexOf("/") + 1);
        let imageUrl = product.designs;
        product.productUrl = imageUrl + hex.image1;
    }

    public getProductName(product: IProductDto) {
        return product.hideProductName ? "" : product.productName;
    }

    private async loadDesigns(product: IProductDto) {
        if (product.designCount > 1 && product.designs[1].key == 1) {
            const designs = (await WebHelper.getJsonData(
                `/api/EventProductEcom/GetProductDesignImages`,
                true
            )) as IDesignImageResults;
            const primaryDesign = product.designs[0];
            product.designs = [];
            product.designs.push(primaryDesign);

            for (const [key, value] of Object.entries(designs.designs)) {
                product.designs.push({
                    key: parseInt(key),
                    value: value,
                });
            }
        }
    }

    public async loadProducts(eventID: number, dealerID: number, categoryID?: number) {
        let focusId = null;
        if (this.productShell && this.productShell.currentCategoryFocusId) {
            focusId = this.productShell.currentCategoryFocusId;
        }

        if (this.productsModel && this.productsModel.lastPage) {
            return;
        }

        const currentPage = this.productsModel ? this.productsModel.currentPage : 0;
        const products = (await WebHelper.getJsonData(
            `/api/EventProductEcom/${eventID}/${dealerID}` + `/${categoryID}/${currentPage}`,
            true
        )) as IProductsDto;

        // TODO: Create a ProductViewModel
        // Note this code is copied over in CartViewModel
        products.products.forEach((product) => {
            product.designs = [];
            try {
                product.colors[0].url = product.productUrl;
            } catch (e) {
                //no colors present
            }
            for (var i = 0; i < product.designCount; i++) {
                if (i == 0) {
                    product.designs.push({
                        key: product.eventProductID,
                        value: product.colors,
                    });
                } else {
                    product.designs.push({
                        key: i,
                        value: product.colors,
                    });
                }
            }
        });

        if (!this.productsModel) {
            this.productsModel = products;
        } else {
            this.productsModel.products.push(...products.products);
            this.productsModel.lastPage = products.lastPage;
        }

        if (focusId && this.productShell) {
            this.productShell.selectCategory(focusId);
        } else if (focusId) {
            this.tryFocus(categoryID);
        }
    }

    public async created() {
        if (this.categoryDto) {
            this.categoryID = this.categoryDto.eventCategoryID;
        }

        if (this.load && this.eventProperties) {
            await this.tryLoadingProducts();
        }

        if (this.productsModel) {
            this.tryFocus();
        }
    }

    private async tryLoadingProducts() {
        if (this.loading) {
            return;
        }

        if (!this.load) {
            return;
        }
        if (this.categoryDto && this.categoryDto.hasPassword && !this.categoryDto.isAuthorized) {
            return;
        }
        if (!this.eventProperties) {
            return;
        }
        if (this.initialProducts) {
            return;
        }

        this.loading = true;
        await this.loadProducts(
            this.eventProperties.eventID,
            this.eventProperties.dealerID,
            this.categoryDto == null ? 0 : this.categoryDto.eventCategoryID
        );
        this.loading = false;
    }

    private async loadNextPage() {
        this.productsModel!.currentPage += 1;
        await this.loadProducts(
            this.eventProperties.eventID,
            this.eventProperties.dealerID,
            this.categoryDto == null ? 0 : this.categoryDto.eventCategoryID
        );
    }

    private tryFocus(categoryID?: number) {
        const shouldFocus = this.eventProperties.selectedCategoryID === categoryID || !categoryID;
        if (!shouldFocus) {
            return;
        }

        Vue.nextTick(() => {
            if (this.eventProperties.selectedProductID) {
                for (let pid = 0; pid < this.productsModel!.products.length; pid++) {
                    const product = this.productsModel!.products[pid];
                    if (product.eventProductID !== this.eventProperties.selectedProductID) {
                        continue;
                    }
                    this.$refs.products[pid].$el.scrollIntoView();
                    this.$emit("scroll");
                    return;
                }
            } else if (categoryID) {
                this.$refs.outerMost.scrollIntoView(false);
                this.$emit("scroll");
            }
        });
    }
}
