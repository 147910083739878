




































import Vue from "vue";
import Component from "vue-class-component";
import { Watch, Prop } from "vue-property-decorator";
import NeedAStoreViewModel from "@/chipply/view-model/NeedAStoreViewModel";
@Component({})
export default class NeedAStoreDialogue extends Vue {
    public name = "NeedAStoreDialogue";

    private nasViewModel = new NeedAStoreViewModel();
    private needFormValid = false;
    private showSnack = false;

    public $refs!: {
        needForm: any;
    };

    @Prop({
        default: false,
        type: Boolean,
    })
    private showNeedDialogue!: boolean;

    @Prop({
        type: Number,
    })
    private eventId!: number;

    private closeButton() {
        this.$emit("close");
    }

    private validateEmail(email: string) {
        const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(email) || "Invalid e-mail.";
    }

    private resetForm() {}

    private async submit() {
        var result = false;
        this.nasViewModel.setEventId(this.eventId);
        if (this.$refs.needForm.validate()) {
            result = await this.nasViewModel.sendStoreRequest();

            if (result) {
                this.showSnack = true;
            }
            this.$emit("submit");
        }
    }
}
