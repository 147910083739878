











































































































































import { WebHelper, Serializer } from "chipply-common";
import Vue from "vue";
import Component from "vue-class-component";
import IEventCategoryDto from "@/chipply/i-event-category-dto";
import IProductDto from "@/chipply/i-product-dto";
import IDesignImageResults from "@/chipply/IDesignImageResults";
import DesignFlag from "./DesignFlag.vue";
import { Prop, Watch } from "vue-property-decorator";
import { mdiTarget } from "@mdi/js";
import CCarousel from "@/components/design-elements/CCarousel.vue";
@Component({
    components: { CCarousel, DesignFlag },
    props: {
        categoryDto: Object,
        bigCard: Boolean,
    },
})
export default class IndividualDisplayCard extends Vue {
    public name = "IndividualDisplayCard";
    public categoryDto!: IEventCategoryDto;
    @Prop({
        type: Object,
    })
    public product!: IProductDto;
    public currentImage = 0;
    public loading = false;
    public bigCard!: boolean;
    private designsLoaded = false;
    private carouselImageUrl = "";
    private previewColor = this.product.colors[0].hexCode1;

    private carouselClassObject = {
        "w3-animate-right": false,
        "w3-animate-left": false,
    };

    private async staggerTransition(forward: boolean) {
        if (forward) {
            this.carouselClassObject["w3-animate-right"] = false;
            this.carouselClassObject["w3-animate-left"] = false;
            await this.$nextTick(() => {});
            await this.$nextTick(() => {});
            this.carouselClassObject["w3-animate-right"] = true;
        } else {
            this.carouselClassObject["w3-animate-left"] = false;
            this.carouselClassObject["w3-animate-right"] = false;
            await this.$nextTick(() => {});
            await this.$nextTick(() => {});
            this.carouselClassObject["w3-animate-left"] = true;
        }
    }

    private created() {
        this.carouselImageUrl = this.product.designs[this.currentImage].value[0].url;
    }

    public getProductVendorSku(product: IProductDto) {
        if (product.hideVendor && product.hideStyle) {
            return "";
        }
        if (product.hideVendor) {
            return product.sku;
        }
        if (product.hideStyle) {
            return product.vendorName;
        }

        return product.vendorName + " - " + product.sku;
    }

    public getProductImageAltText(product: IProductDto) {
        const id = product.eventProductID;

        return `Product Image: ${id}`;
    }

    public async changeDisplayColor(product: IProductDto, colorId: any, itemNumber: number) {
        if (product.designCount == 1) {
            let imageUrl = product.productUrl.substring(0, product.productUrl.lastIndexOf("/") + 1);

            let targetColorUrl = "";
            product.colors.forEach((color: any) => {
                if (color.hexCode1 === colorId) {
                    targetColorUrl = color.image1;
                }
            });
            product.productUrl = imageUrl + targetColorUrl;
        } else {
            let currentColors = product.designs[itemNumber].value;
            let targetColorUrl = "";

            this.previewColor = colorId;
            currentColors.forEach((color) => {
                if (color.hexCode1 === colorId) {
                    targetColorUrl = color.image1;
                }
            });

            let imageUrl = currentColors[0].url.substring(0, currentColors[0].url.lastIndexOf("/") + 1);
            this.carouselImageUrl = imageUrl + targetColorUrl;
        }
    }

    public getProductName(product: IProductDto) {
        return product.hideProductName ? "" : product.productName;
    }

    private toolTipText() {
        let ele = document.createElement("div");
        if (this.product.shortDesc) {
            ele.innerHTML = this.product.shortDesc;
        }

        return ele.innerText;
    }

    private descText() {
        const ele = document.createElement("div");
        if (this.product!.shortDesc) {
            ele.innerHTML = this.product!.shortDesc;
            const target = ele.getElementsByTagName("span")[0];
            if (target) {
                target.style.fontSize = "14px";
            }
        }

        return ele.innerHTML;
    }

    public navigateToProduct(product: IProductDto, categoryDto: IEventCategoryDto) {
        window.location.href =
            "product-detail-shell.aspx?pid=" +
            product.eventProductID +
            (categoryDto ? "&cid=" + categoryDto.eventCategoryID : "");
        let currentUrl = window.location.href;
        let historyUrl = new URL(currentUrl);

        if (!historyUrl.searchParams.has("apid")) {
            historyUrl.searchParams.set("apid", product.eventProductID.toString());
        } else {
            historyUrl.searchParams.delete("apid");
            historyUrl.searchParams.set("apid", product.eventProductID.toString());
        }

        if (!historyUrl.searchParams.has("acid") && categoryDto) {
            historyUrl.searchParams.set("acid", categoryDto.eventCategoryID.toString());
        } else {
            historyUrl.searchParams.delete("acid");
            if (categoryDto) {
                historyUrl.searchParams.set("acid", categoryDto.eventCategoryID.toString());
            }
        }

        history.replaceState(null, "", historyUrl.href);
    }

    private async loadDesigns() {
        this.loading = true;
        if (this.product.designCount > 1 && this.product.designs[1].key == 1) {
            const designs = (await WebHelper.getJsonData(
                `/api/EventProductEcom/GetProductDesignImages/` + this.product.eventProductID,
                true
            )) as IDesignImageResults;
            const primaryDesign = this.product.designs[0];
            this.product.designs = [];
            this.product.designs.splice(0, this.product.designs.length);
            this.product.designs.push(primaryDesign);

            for (const [key, value] of Object.entries(designs.designs)) {
                this.product.designs.push({
                    key: parseInt(key),
                    value: value.slice(0, 5),
                });
            }
        }

        this.designsLoaded = true;
    }

    private async prevImage() {
        if (!this.designsLoaded) {
            await this.loadDesigns();
        }
        if (this.currentImage > 0) {
            this.currentImage -= 1;
            this.changeDisplayColor(this.product, this.previewColor, this.currentImage);
        } else if (this.currentImage <= 0) {
            this.currentImage = this.product.designCount - 1;
            this.changeDisplayColor(this.product, this.previewColor, this.currentImage);
        }
    }

    private async nextImage() {
        if (!this.designsLoaded) {
            await this.loadDesigns();
        }

        if (this.currentImage < this.product.designCount - 1) {
            this.currentImage += 1;
            this.changeDisplayColor(this.product, this.previewColor, this.currentImage);
        } else if (this.currentImage >= this.product.designCount - 1) {
            this.currentImage = 0;
            this.changeDisplayColor(this.product, this.previewColor, this.currentImage);
        }
    }
}
