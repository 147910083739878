import { WebHelper } from "../../../../common";

export default class NeedAStoreViewModel {
    public firstName = "";
    public lastName = "";
    public emailAddress = "";
    public notes = "";

    //might need this
    public eventId = 0;

    public setEventId(eventId: number) {
        this.eventId = eventId;
    }

    public async sendStoreRequest() {
        const results = await WebHelper.postJsonData("/api/Event/RequestStore", this);
        return JSON.parse(results);
    }
}
