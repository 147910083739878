import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import "@/plugins/vuetify";
import "es6-promise/dist/es6-promise.auto.min";
import "whatwg-fetch";
import Store from "@/components/page/StorePage.vue";
import RecaptchaClient from "@/chipply/verify/RecaptchaClient";

(window as any).Store = Store;
(window as any).vuetify = vuetify;
(window as any).RecaptchaClient = RecaptchaClient;

if (Vue.config.devtools) {
    // Allow this code to run only when running for the .html page in the vue development server
    // We don't want to run this code in the .aspx shell pages as they will also have this code
    // The containing if statement prevents the component from loading twice in .aspx shell pages.
    document.addEventListener("DOMContentLoaded", () => {
        const t = new Store({ vuetify, el: "#app" });
    });
}

export default Store;
